@import "src/theme/variables.scss";

html,
body {
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: 'Libre Franklin';
  src:url('../../assets/fonts/LibreFranklin-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin';
  src:url('../../assets/fonts/LibreFranklin-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin';
  src:url('../../assets/fonts/LibreFranklin-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $background;
  bottom: 0;
  color: $fontColor;
  font-family: "Libre Franklin", monospace;
  font-size: $mediumFontSize;
  left: 0;
  margin: 0;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  text-rendering: geometricPrecision;
  top: 0;
}

body > div:first-child {
  display: flex;
  min-height: 100%;
}